import type { Shop, User } from "@/types";
import { isAfter } from "date-fns";
import { Percent } from "lucide-react";
import { Fragment } from "react/jsx-runtime";

const texts = [
  "3 Days of BLACK FRIDAY DEALS",
  "NOV 28th, 29th & 30th!",
  "3 Days of BLACK FRIDAY DEALS",
  "NOV 28th, 29th & 30th!",
];

interface Props {
  shop: Shop;
  user?: User | null;
}

const Marquee = ({ shop, user }: Props) => {
  if (isAfter(new Date(), new Date("2024-11-27"))) {
    return null;
  }

  return (
    <div className="bg-kp-black text-kp-primary py-1 overflow-hidden">
      <div className="relative flex overflow-x-hidden">
        <div className="animate-marquee whitespace-nowrap flex items-center">
          {texts.map((txt, index) => (
            <Fragment key={index}>
              <Percent className="text-kp-accent mx-2" size={24} />
              <span className="mx-4 text-lg font-bold">{txt}</span>
            </Fragment>
          ))}
        </div>
        <div className="absolute top-0 animate-marquee2 whitespace-nowrap flex items-center">
          {texts.map((txt, index) => (
            <Fragment key={index}>
              <Percent className="text-kp-accent mx-2" size={24} />
              <span className="mx-4 text-lg font-bold">{txt}</span>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Marquee;
